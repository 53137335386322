
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #5e17eb; /* Color púrpura inicial */
  --color-secondary: #8c03e2;
}
body {
  margin: 0;
  padding: 5px 0px 0px 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box-card-eticket {
  width: 190px !important;
  height: 100px !important;
}
.bg-ex{
  height: 100vh;
}
.pointer-trash{
  cursor: pointer;
}
.pointer-trash:hover {
  width: 30px;
  height: 30px;
}


.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51,51,51,0.7);
  z-index: 10;
}

.red-color{
  color:rgb(177, 7, 7)
}
.yellow-color{
  color:rgb(161, 161, 15)
}
.green-color{
  color:green
}

.orange-color{
  color:orange
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;

}

th, td {
  padding: 12px 12px;
  padding-left: 12px !important;
  text-align: left;
}

@media (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display:block;
  }

  thead tr {
    display: none; /* Ocultar los encabezados en pantallas pequeñas */
  }

  tr {
    margin-bottom: 15px;
  }

  td {
    display: flex;
    justify-content:flex-end !important;
    padding-left: 10%;
    position: relative;
    align-items: flex-start;
    text-align: left; }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    white-space: nowrap;
  }
  .search-input {
   display: none !important;
  }
}









/* select option{
  background-color: rgb(41, 41, 41) !important;
} */


